import React from "react"

import Template from "../../components/Template"
import SEO from "../../components/seo"
import style from "./style.module.scss"
import Image3 from "./3.png"
import Image4 from "./4.png"

const Episode1 = () => (
  <Template>
    <SEO title="1. Lalande" />
    <div className={style.episode}>
      <div className={style.heroImage}>
        <div className={style.title}>
          <div className={style.titleContent}>
            <h2>Episode 1.</h2>
            <h1>Charlevere</h1>
          </div>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.copy}>
          <p>
            Charlevere chewed on a mouthful of goat as she contemplated the man
            kneeling on the floor before her.
          </p>
          <p>
            He was tied with his hands behind his back, disheveled and sunburnt
            after a long trip across the Natansi desert. No doubt also confused
            and frightened after being taken prisoner by her patrolmen two
            nights prior.
          </p>
          <p>
            "Where did you say you got the beast?" asked Charlevere after a sip
            of wine.
          </p>
          <img className={style.inlineImage} src={Image3} />
          <p>
            The mans voice was hoarse, dry coughs breaking up his words.
            "Please, madame, I beg you. We didn't know what it was. We didn't
            know—"
          </p>
          <p>
            "Answer the question," said Charlevere, her tone shifting
            dangerously. "Did you purchase it from a trader? Steal it?"
          </p>
          <p>
            "No, it's as I already told your men, I swear it on my life. We were
            hunting in the Gokyo Ri, and saw it on the ridgeline. I thought it
            was a Markhorein stag! Notched an arrow... It wasn't until we
            brought it down I saw it was... something else."
          </p>
          <p>
            Charlevere stared at him irritably. Did he not know who she was? It
            was her own fathers life's work to eradicate the Animalkind from the
            wild and stop the spread of the disease the alchemists had
            accidentally created along with them. His armies had burned
            thousands of acres of grassland, poisoned waterways, and logged
            forests as far as the western coast to ensure the escaped creatures
            could not find a survivable habitat anywhere.
          </p>
          <p>It wasn't possible to find them in the wild.</p>
          <p>
            The only Animalkind that remained alive were here at Natans, far
            away from the good people of the royal city. Besides, there was no
            way this man, who had been found crossing the desert in a small
            wagon very near the base, was there coincidentally. He claimed he
            was looking for help, but Charlevere knew he was only trying to buy
            himself time.
          </p>
          <p>
            "If you don't start telling the truth, I'll have my jaguarn rip your
            throat out."
          </p>
          <p>
            At her threat, the man twisted his body away from her, searching the
            dark corners of the room until he found a pair of yellow eyes
            staring back at him.
          </p>
          <p>
            "Please madame," he yelped, scooting forward on the smooth stone.
            "Anything but that. Have mercy."
          </p>
          <p>
            "Stop moving," Charlevere commanded coldly, her eyes flitting toward
            the barely visible silhouette of a hulking figure in the darkness.
            "Or you may trigger his instincts to chase you."
          </p>
          <p>
            The man sobbed quietly while Charlevere took another sip of her
            wine. After growing up most of her life in a military compound, she
            had no soft spots for pleading - especially from the likes of adult
            men. She was contemplating what to do with him when there was a
            sudden knock on the door that made her set down her glass and sweep
            across the room, long blue dress flowing out behind her like water.
          </p>
          <p>
            "What is it?" she said, opening the door to find a soldier dressed
            in uniform the color of sand, holding one hand against his eyebrow
            in hasty salute.
          </p>
          <p>
            "Madame, I beg your pardon for the interruption during your meal
            hour, but there has been a bit of a - ah - problem," his voice
            faltered a little, but under Charlevere's expectant gaze, he
            continued. "It's with the Animalkind. The one the patrol found? Ah,
            yes. Well. It's a bit stronger than it looks, and um, it...sort
            of... got out."
          </p>
          <p>
            "Got out." Charlevere repeated tonelessly, her eyebrows raising.
            "Where is it now?"
          </p>
          <p>"In the library," said the soldier, swallowing nervously.</p>
          <p>
            "The library? Well that's lovely." Charlevere's eyebrows knit as she
            tried to piece together what could have happened, but one look at
            the young soldier's face told her she wouldn't be getting the
            information she needed out of him. With a sigh, she continued before
            he attempted to explain himself. "Nevermind. I'll have Lalande take
            care of it, he needs the practice anyway."
          </p>
          <p>
            She looked over her shoulder at the man on the floor, and then back
            at the soldier. "Take him to the infirmary and get him cleaned up. I
            shall be very keen to pick up our little conversation later."
          </p>
          <p>
            "Yes, madame," said the soldier, seeming as relieved as the prisoner
            to be out of her wake.
          </p>
          <p>"Lalande. Come along."</p>
          <p>
            From the shadows, a huge figure appeared that made both men shirk
            back. Half man, half wildcat, the creature was dressed in a pair of
            loose, silky black pants called serols which perfectly matched his
            fur, and his ears and tail were adorned with gold the same color as
            his eyes.
          </p>
          <p>
            He moved in complete silence behind her, like a second shadow. She'd
            raised Lalande since her father first gifted him to her as a tiny
            cub, and knew he'd never harm her. In a strange way, he'd actually
            become her closest confidant at Natans, like a bodyguard she could
            command the same way as an attack dog.
          </p>
          <p>
            Her ascent to the role of Overseer after her father's death seven
            years ago had never sat well with the soldiers. If it wasn't for the
            fear Lalande's presence commanded, she speculated they would've
            conspired to take her life years ago. In the early years, it was
            clear the men stationed in the hostile desert encampment would have
            much preferred to see one of their own take control of the base -
            but there was little anyone dared to do or say about it now.
          </p>
          <p>
            "It astounds me how grown men can be as incompetent as newborns,"
            she complained to him as they descended a wide staircase. The walls
            were made of the same smooth material as the floors they walked on —
            gold and red sand mixed into concrete slabs, some six foot thick. A
            fortress as impenetrable as the desert it was built upon.
          </p>
          <p>
            After walking down a long corridor, she heard shouting in the
            distance, growing louder. Soon, the sound of voices and clanging
            metal were everywhere as she rounded a corner to find a group of
            uniformed men spilling out of an open door. At the sight of her, and
            the huge black jaguarn behind her, soldiers parted like water, some
            of them covering their noses with their shirtsleeves.
          </p>
          <p>
            Charlevere stepped into the library, a huge stone room decorated
            with blue tapestries and lined wall-to-wall with books. Although the
            base was remote, it was sprawling and well-equipped, with indoor
            barracks, cellars, and two full kitchens.
          </p>
          <p>
            Tall aisles filled with even more books rose from the floor before
            her, a few soldiers standing at one end with their heads tilted
            back.
          </p>
          <p>
            "Here kitty kitty," one of them said to a murmur of laughter from
            the others. Another held a long pike over his head, prodding the
            air. Charlevere tracked the focal point of their gaze upwards, where
            on the top of one of the huge wooden bookcases, she could see a
            small gray figure balanced precariously, a few feet out of reach.
          </p>
          <p>
            She shook her head at the absurdity of the situation. The last time
            she'd seen the animal, it was being dragged out of the back of a
            wagon by gloved soldiers; unconscious, footpaws hastily manacled
            together, its fur patchy and raw from the heat. Now, it was somehow
            besting twelve men? Unbelievable.
          </p>
          <p>
            Charlevere turned to the jaguarn. "Lalande, get her down from there
            before she makes a mess. Some of those titles are more than two
            hundred years old."
          </p>
          <p>
            He growled in response, but nevertheless stepped forward, as fixated
            on the creature as a hunting dog on a bird.
          </p>
          <p>
            At the sight of him, the smaller animal hissed and bared its teeth.
            Although it was only about half Lalande's size, the two of them were
            clearly a similar kind, with the same mix of feline features and fur
            covering otherwise human bodies.
          </p>
          <img className={style.inlineImage} src={Image4} />
          <p>
            She watched with interest as Lalande stalked along the bottom of the
            aisle. It would take little for him to scale the bookshelf — she'd
            seen him leap far higher in pursuit of defecting soldiers — but as
            he got closer, the smaller animals desperation heightened until it
            risked a daring dash along the top of the shelf, and leapt over the
            heads of the crowd through the open door, hissing and snarling so
            loudly that even armed soldiers scrambled to get away from her.
            Charlevere barely flinched as the ball of gray fur launched overhead
            and skidded across the floor, claws scratching for purchase as it
            took off in a panicked run.
          </p>
          <p>
            When Lalande appeared at her side, Charlevere canted her head the
            direction she'd seen the creature flee.
          </p>
          <p>
            "I'm exhausted from all this running around today, Lalande," she
            complained again. "I'm sure you can handle things from here?"
          </p>
          <p>
            He paused to scent the air before disappearing down the corridor
            with another soft, acquiescing growl. Charlevere had no doubt he'd
            find her. In all the years she'd known him, he had never once failed
            to carry out her orders
          </p>
        </div>
      </div>
      <div className={style.content}>
        <a href="/2-lalande">Next episode</a>
      </div>
      <div className={style.footerImage}>
        <div className={style.gradient}></div>
      </div>
    </div>
  </Template>
)

export default Episode1
